import './App.css';
import { useEffect } from 'react';

/**
 * Main App component
 * @returns {JSX.Element} The rendered App component
 */
function App() {
  useEffect(() => {
    // Load Twitter widgets script
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="App">
      <header className="App-header">
        <h1>Yatna Creations</h1>
        <main className="main">
          <p>
            My name is <a href="https://www.linkedin.com/in/pjaya/" target="_blank" rel="noopener noreferrer">P Jay</a>. 
            I am rediscovering my passion for Web development and am currently learning React JS 
            to develop webpages that provide value for the readers. 
            Trying to reverse some of what Noah has observed here.
          </p>
          <blockquote className="twitter-tweet">
            <p lang="en" dir="ltr">
              A decade ago I felt like I could find anything on the Web. Now I feel like I can barely find anything. 
              People just don&#39;t put information on web pages anymore.
            </p>
            &mdash; Noah Smith 🐇🇺🇸🇺🇦 (@Noahpinion) <a href="https://twitter.com/Noahpinion/status/1818776478315954200?ref_src=twsrc%5Etfw">July 31, 2024</a>
          </blockquote>
          <p>
            Current projects: Common Wealth Stadium, CA & Craigslist Studies
          </p>
          <p>
            Will be adding more projects soon as I learn along the way.
          </p>
        </main>
      </header>
    </div>
  );
}

export default App;